import regexUtil from '@/utils/regex';
import {
  createValidationObjectSchema,
  formatMatches,
} from '@/utils/validations';
import * as Yup from 'yup';

export const UserSchema = createValidationObjectSchema({
  roles: {
    required: true,
    errorTextFieldLabel: 'Role',
  },
  email: {
    format: 'email',
    required: true,
  },
  first_name: {
    required: true,
  },
  last_name: {
    required: true,
  },
});
