
import { defineComponent } from 'vue';
import UserModal from '@/views/new-design/pages/Settings/Users/UserModal.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  Getters as UserGetters,
  Actions as UserActions,
  Mutations as UserMutations,
} from '@/store/enums/UserEnums';
import { Getters as AuthGetter } from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';
import DataTable from '@/components/datatables/KTDataTable.vue';
import _ from 'lodash';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import { User } from '@/models/UserModel';
import { Getters } from '@/store/enums/StoreEnums';
import { acronym } from '@/utils/text';
import Avatar from '@/components/avatars/Avatar.vue';
import {
  Getters as NotificationGetters,
  Actions as NotificationActions,
} from '@/store/enums/NotificationEnums';
import text from '@/utils/text';
import useBreakpoints from 'vue-next-breakpoints';

const initialValue = {
  role: '',
  email: '',
  first_name: '',
  last_name: '',
} as unknown as User;

export default defineComponent({
  components: {
    UserModal,
    DataTable,
    //UserManagerModal,
    //EditUserModal,
    Navigator,
    Avatar,
  },
  data: () => {
    return {
      formErrors: {},
      addingUser: false,
      editingUser: false,
      table: {
        currentPage: 1,
        totalPages: 0,
        perPage: 15,
        ordering: false,
        items: [],
      },
      debounceGetUsers: Function(),
      selectedUser: initialValue,
      loading: false,
      showModal: false,
      showDeletedUser: false,
      deletingUser: null,
      loadingUsers: true,
    };
  },
  watch: {
    showDeletedUser(value) {
      if (value) {
        this.fetchUsers({ deleted: true });
      } else {
        this.fetchUsers({});
      }
    },
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetter.GET_AUTH_USER,
      users: UserGetters.GET_ALL_USERS,
      usersListPagination: UserGetters.GET_USER_PAGINATION,
      getErrors: UserGetters.GET_USER_ACTION_ERROR,
      defaultBGColor: Getters.GET_DEFAULT_BACKGROUND_COLOR,
      defaultTextColor: Getters.GET_DEFAULT_TEXT_COLOR,
      settings: NotificationGetters.GET_CURRENT_SETTINGS,
    }),
    backgroundColor() {
      return this.authUser.business_color ?? this.defaultBGColor;
    },
    textColor() {
      return this.authUser.business_text_color ?? this.defaultTextColor;
    },
    formattedUsers(){
      return this.users.map(item => {
        item.name = `${ item.first_name } ${ item.last_name }`;
        item.role = text.formatRoleText(item.roles.join(', '));

        return item;
      })
    },
    breakpoint(){
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders(){
      return [
        { name: 'Name', key: 'name' },
        { name: 'Email', key: 'email', hidden: this.isMobile },
        { name: 'Role', key: 'role', hidden: this.isMobile },
        { name: 'Action', key: 'action', align: 'right', sortable: false },
      ]
    }
  },
  async mounted() {
    await this.fetchUsers();
    this.fetchCurrentSettings();
  },
  created() {
    this.debounceGetUsers = _.debounce(() => {
      this.fetchUsers();
    }, 1000);
  },
  methods: {
    ...mapActions({
      fetchUsersList: UserActions.FETCH_USERS,
      addUser: UserActions.CREATE_NEW_USER,
      deleteUser: UserActions.DELETE_USER,
      updateUser: UserActions.UPDATE_USER,
      restoreUser: UserActions.RESTORE_USER,
      fetchCurrentSettings: NotificationActions.FETCH_CURRENT_SETTINGS,
    }),
    ...mapMutations({
      setUsers: UserMutations.SET_USERS,
    }),
    async fetchUsers(params = {}) {
      if (this.showDeletedUser) {
        params = { deleted: true };
      }

      this.loadingUsers = true;
      await this.fetchUsersList(params).finally(() => this.loadingUsers = false );

      this.table.items = this.formattedUsers;
      this.table.currentPage = this.usersListPagination?.current_page;
      this.table.totalPages = this.usersListPagination?.total_pages;
      this.table.perPage = this.usersListPagination.per_page;
    },
    handleAcronym(user: User) {
      if (user.first_name === '' || user.last_name === '')
        return acronym(user.email);

      return acronym(`${user.first_name} ${user.last_name}`);
    },
    handleDeleteUser(id) {
      if (id === this.authUser.id) return;

      if (this.isAddedInNotificationSettings(id)) {
        toasts.error(
          {},
          'You cannot delete a user who has been added to the Due Authorization or Monthly Report Notification Settings.'
        );
        return false;
      }

      toasts.confirm('Are you sure to remove user?', (result) => {
        if (result.isConfirmed) {
          this.deleteUser({ id: id })
            .then(() => {
              toasts.success('User has been deleted successfully.');
              this.debounceGetUsers();
            })
            .catch(() => {
              const { errors, message } = this.getErrors;
              if (errors) {
                this.formErrors = errors;
                return;
              }
              toasts.error(errors, message);
            });
        }
      });
    },
    createNewUser() {
      this.selectedUser = initialValue;
      this.showModal = true;
    },
    editUser(user) {
      this.selectedUser = user;
      this.showModal = true;
    },
    async handleFormSubmitted() {
      this.selectedUser = initialValue;
      await this.fetchUsers();
      this.showModal = false;
    },
    handleModalClosed() {
      this.selectedUser = initialValue;
      this.showModal = false;
    },
    handleRestoreUser(item) {
      if (item.id === this.authUser.id) return;

      toasts.confirm('Are you sure you want to restore user?', (result) => {
        if (result.isConfirmed) {
          this.restoreUser({ id: item.id })
            .then(() => {
              toasts.success('User has been restored successfully.');
              this.debounceGetUsers();
            })
            .catch(() => {
              const { errors, message } = this.getErrors;
              if (errors) {
                this.formErrors = errors;
                return;
              }
              toasts.error(errors, message);
            });
        }
      });
    },
    isAddedInNotificationSettings(id) {
      return (
        this.settings.due_authorization == id ||
        this.settings.monthly_report == id
      );
    },
  },
});
