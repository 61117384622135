
import { defineComponent } from 'vue';
import Modal from '@/components/modals/Modal.vue';

import UserForm from '@/components/forms/user/UserForm.vue';
import { User } from '@/models/UserModel';

export default defineComponent({
  name: 'user-modal',
  emits: ['formSubmitted', 'modalClosed', 'update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default: () => {
        return {} as unknown as User;
      },
    },
  },
  components: {
    UserForm,
    Modal,
  },
  data: () => ({
    updateMode: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    handleFormSubmitted() {
      this.$emit('formSubmitted');
    },
    handleFormCancel() {
      this.$emit('modalClosed');
    },
  },
  watch: {
    selectedUser(newValue, oldValue) {
      this.updateMode = false;

      if (typeof newValue.id !== 'undefined') {
        this.updateMode = true;
      }
    },
  },
});
