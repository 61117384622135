
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';

import RoleSelect from '@/components/forms/dropdown/RoleSelect.vue';
import FormField from '@/components/forms/FormField.vue';

import { User } from '@/models/UserModel';
import { UserSchema } from '@/schemas/user.schema';
import { mapActions, mapGetters } from 'vuex';

import { Actions, Getters } from '@/store/enums/UserEnums';
import toasts from '@/utils/toasts';
import { Roles } from '@/models/TenantModel';

const initialValues = {
  roles: '',
  first_name: null,
  last_name: null,
  email: null,
} as unknown as User;

export default defineComponent({
  emits: ['formSubmitted', 'formError', 'formCancel'],
  name: 'user-form',
  props: {
    user: {
      type: Object,
      default: undefined,
    },
  },
  data: () => ({
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    updateMode: false,
    roles: [
      { label: 'Property Manager', value: 'pm' },
      { label: 'Principal', value: 'principal' },
    ],
  }),
  mounted() {
    this.form = this.$refs.userForm as typeof Form;
  },
  components: {
    Form,
    RoleSelect,
    FormField,
  },
  computed: {
    ...mapGetters({
      actionError: Getters.GET_USER_ACTION_ERROR,
    }),
    formSchema() {
      return UserSchema;
    },
    userRole() {
      return this.formData?.roles[0];
    },
    initializedFormData() {
      return typeof this.user !== 'undefined' ? this.user : initialValues;
    },
  },
  methods: {
    ...mapActions({
      createNewUser: Actions.CREATE_NEW_USER,
      updateUser: Actions.UPDATE_USER,
    }),
    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
    clearForm() {
      this.form.resetForm();
    },
    async handleSubmitForm(values) {
      if (this.loading) return;
      this.loading = true;

      const roles = [values.roles] as Array<Roles>;

      this.formData = values;
      this.formData.roles = roles;
      this.formData.id = this.updateMode ? this.user?.id : '';

      return await (this.updateMode ? this.updateUser : this.createNewUser)(
        this.formData
      )
        .then(() => {
          this.clearForm();
          toasts.success('User successfully saved');

          this.$emit('formSubmitted', this.formData);
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);

          this.$emit('formError', this.formData);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleRoleClose() {
      this.validateFormField('roles');
    },
    handleRoleChange(value) {
      this.setFieldValue('roles', value);
    },
    handleCancel() {
      this.$emit('formCancel');
    },
  },
  watch: {
    user(value) {
      this.updateMode = false;
      this.formData = initialValues;

      if (typeof value.id !== 'undefined') {
        this.formData = value;
        this.updateMode = true;
      }
    },
  },
});
